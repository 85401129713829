const Routes = {
  ACCOUNT_DETAILS: "/account/details",
  ANALYTICS: "/analytics",
  APPS: "/apps",
  APPS_SETTINGS: "/apps/:appId",
  BULK_IMPORT_PRODUCTS: "/bulk-import-products",
  CATEGORIES: "/categories",
  CREATE_CATEGORY: "/categories/create",
  CREATE_CUSTOMER: "/customers/create",
  CREATE_DISCOUNT: "/promotions/create-discount",
  CREATE_LIST: "/lists/create",
  CREATE_PRODUCT: "/create-product",
  CUSTOMER_DETAILS: "/customers/:id",
  CUSTOMERS: "/customers",
  DISCOUNT_DETAILS: "/promotions/:id",
  EDIT_CATEGORY: "/categories/edit/:id",
  EDIT_LIST: "/lists/:id",
  GIFTCARD_CREATE: "/giftcards/new",
  GIFTCARD_DETAILS: "/giftcards/:id",
  GIFTCARDS: "/giftcards",
  HELP: "/help",
  HOME: "/home",
  IMPERSONATE: "/imp",
  LISTS: "/lists",
  LOGIN_PATH: "/login",
  NOT_FOUND: "/404",
  OPERATIONS: "/operations",
  ORDER_CREATE: "/orders/create",
  ORDER_CREATE_WHOLESALE: "/orders/create-wholesale",
  ORDER_DETAILS: "/orders/:id",
  ORDER_LIST: "/orders",
  ORDERS_EXPORT: "/orders/export",
  ORDERS_PICKLIST: "/operations/orders-picklist",
  ORDERS_PICKLIST_EXPORT: "/operations/orders-picklist/export",
  PAYMENTS: "/payments",
  PAYMENTS_STRIPE_CANCEL: "/payments/stripe/cancel",
  PAYMENTS_STRIPE_SUCCESS: "/payments/stripe/success",
  PRODUCT: "/products/:id",
  PRODUCT_CREATE_NEW: "/products/create",
  PRODUCT_LIST: "/products",
  PRODUCTS_PICKLIST: "/operations/products-picklist",
  PROMOTIONS: "/promotions",
  RESET_PASSWORD: "/reset-password",
  SERVICE_UNAVAILABLE_PAGE: "/500",
  SIGNUP_PATH: "/onboard",
  STAFF: "/staff/:id",
  STAFF_CREATE: "/staff/create",
  STAFF_LIST: "/staff-list",
  STORE: "/store",
  STORE_APPEARANCE: "/store/appearance",
  STORE_AVAILABILITIES: "/store/availability-overrides",
  STORE_AVAILABILITIES_CREATE: "/store/availability-overrides/create",
  STORE_AVAILABILITIES_DETAILS: "/store/availability-overrides/:id",
  STORE_COMMUNICATION: "/store/communication",
  STORE_GENERAL_SETTINGS: "/store/general",
  STORE_LOCATIONS: "/store/locations",
  STORE_LOCATIONS_CREATE: "/store/locations/create",
  STORE_LOCATIONS_DETAIL: "/store/locations/:id",
  SUBSCRIPTIONS: "/subscriptions",
  SUBSCRIPTIONS_COLLECTIONS: "/subscriptions/collections",
  SUBSCRIPTIONS_COLLECTIONS_CREATE: "/subscriptions/collections/create",
  SUBSCRIPTIONS_COLLECTIONS_DETAILS: "/subscriptions/collections/:id",
  SUBSCRIPTIONS_CREATE: "/subscriptions/create",
  SUBSCRIPTIONS_DETAILS: "/subscriptions/:id",
  SUBSCRIPTIONS_FEATURE_NOT_ENABLED: "/subscriptions/not-enabled",
  UNAUTHORIZED: "/unauthorized",
  WHOLESALE_CLIENT_CREATE: "/wholesale/businesses/create",
  WHOLESALE_CLIENT_DETAILS: "/wholesale/businesses/:id",
  WHOLESALE_CLIENTS: "/wholesale/businesses",
  WHOLESALE_LIST_CREATE: "/wholesale/lists/create",
  WHOLESALE_LIST_DETAILS: "/wholesale/lists/:id",
  WHOLESALE_LISTS: "/wholesale/lists",
};

export default Routes;
