import { gql, useMutation } from "@apollo/client";
import { usePasswordToggle } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import { Button, CircularProgress, Grid, Hidden, Link, makeStyles, TextField, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

import { isLoggedInVar } from "../../../cache/authPolicy";
import { notificationVar } from "../../../cache/notificationPolicy";
import Logo from "../../../components/Logo";
import Routes from "../../../Constants/Routes";
import loginImage from "../../../images/loginPageImages/mica.png";

export enum FormMode {
  Login,
  ResetPassword,
}

const MAIGC_SIGN_IN_SUPPLIER = gql`
  mutation MagicSignInSupplier($email: String!) {
    authMagicSignInLinkByEmailForSupplier(email: $email)
  }
`;

const LOG_IN = gql`
  mutation SupplierLogin($input: LoginInput!) {
    supplierLogin(input: $input)
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    margin: "auto",
    padding: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  fullHeightSection: {
    height: "100%",
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  loginImage: {
    height: "100vh",
    objectFit: "cover",
    width: "100%",
  },
  spacing: {
    marginTop: theme.spacing(2),
  },
}));

type AuthInput = {
  email: string;
  password: string;
};

const AuthForm = ({ children, formMode }) => {
  const classes = useStyles();
  const history = useHistory();
  const loggedIn = isLoggedInVar() || localStorage.getItem("auth_token");
  const { handleSubmit, register, watch } = useForm<AuthInput>({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const { PasswordToggle, passwordInputType } = usePasswordToggle(watch);

  useEffect(() => {
    if (loggedIn) {
      history.push("/");
    }
  }, [history, loggedIn]);

  const [logIn, { loading: logInLoading }] = useMutation(LOG_IN, {
    context: { source: DATALAYER },
    onCompleted({ supplierLogin: token }) {
      if (token) {
        localStorage.setItem("auth_token", token);
        isLoggedInVar(true);
        history.push("/");
      }
    },
  });

  const [magicSignInSupplier, { loading: resetPasswordLoading }] = useMutation(MAIGC_SIGN_IN_SUPPLIER, {
    context: { source: DATALAYER },
    onCompleted() {
      notificationVar({
        message:
          "If your account exists, you will receive a one-click sign in link in your inbox. If you don't receive an email, please check your spam folder.",
        severity: "success",
      });
    },
    onError: () => {
      notificationVar({
        message: `
          This account doesn't exist or the request is invalid. Please try
          registering for a new account or contact support.
        `,
        severity: "error",
      });
    },
  });

  const onSubmit = data => {
    const email = data.email.toLowerCase();

    switch (formMode) {
      case FormMode.Login: {
        logIn({
          variables: {
            input: {
              email,
              password: data.password,
            },
          },
        });

        break;
      }

      case FormMode.ResetPassword: {
        magicSignInSupplier({
          variables: {
            email,
          },
        });

        break;
      }
    }
  };

  const loading = logInLoading || resetPasswordLoading;

  return (
    <>
      <Grid className={classes.fullHeightSection} container>
        <Grid className={classes.container} item md={5} sm={7} xs={12}>
          <Logo />
          {children}
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              className={classes.input}
              fullWidth
              gutterBottom={2}
              label="Email address"
              name="email"
              type="email"
              inputRef={register}
              variant="outlined"
            />
            {formMode === FormMode.Login && (
              <>
                <TextField
                  className={classes.input}
                  fullWidth
                  label="Password"
                  inputRef={register}
                  name="password"
                  type={passwordInputType}
                  InputProps={{
                    endAdornment: PasswordToggle,
                  }}
                  variant="outlined"
                />
                <Typography color="textSecondary" variant="caption">
                  <Link component={RouterLink} to={Routes.RESET_PASSWORD}>
                    Forgot your password?
                  </Link>
                </Typography>
              </>
            )}
            <Button
              className={classes.spacing}
              color="primary"
              disabled={loading}
              fullWidth
              type="submit"
              variant="contained"
            >
              {loading ? (
                <CircularProgress color="inherit" disableShrink size={22} variant="indeterminate" />
              ) : formMode === FormMode.Login ? (
                "Log In"
              ) : (
                "Get the Link"
              )}
            </Button>
          </form>
          {!loggedIn && (
            <>
              {formMode !== FormMode.Login && (
                <Typography color="textSecondary" variant="caption">
                  Already have an account?{" "}
                  <Link component={RouterLink} to={Routes.LOGIN_PATH}>
                    Log In
                  </Link>
                </Typography>
              )}
            </>
          )}
        </Grid>
        <Hidden smDown>
          <Grid className={classes.fullHeightSection} item md={7}>
            <img alt="signUpImage" className={classes.loginImage} src={loginImage} />
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};

export default AuthForm;
